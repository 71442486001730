@import '../../../base/assets/scss/_base/config';
@import 'local_news';

.m-news {
  font-family: var(--font-family-base);
  font-size: 14px;
  margin-bottom: 15px;
  text-align: left;

  .article{

    &-content{
      .article-link{
        text-decoration: none;

        .article-info{
          .provider{
            display: flex;
            max-width: 234px;
            align-items: center;

            &-logo{
              float: left;
              margin-right: 8px;
            }

            &-name{
              font-size: 11px;
              font-weight: 500;
              color: $backwater;
            }
          }
          .title{
            font-size: var(--cb-small-heading-font-size);
            margin-top: 8px;
            font-weight: 600;
            font-family: var(--font-family-heading);
            line-height: var(--cb-small-heading-line-height);
            color: $batcave;
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;

            @media screen and (min-width: map-get($breakpoints, xl)) and (max-width: (map-get($breakpoints, xxl) - 1px)) {
              -webkit-line-clamp: 3;
            }

            @media screen and (min-width: map-get($breakpoints, l)) and (max-width: (map-get($breakpoints, xl) - 1px))  {
              font-size: 13px;
            }
            @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, l) - 1px))  {
              font-size: 13px;
            }
            @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: (map-get($breakpoints, s) - 1px))  {
              font-size: 13px;
              font-weight: 700;
            }
            @media screen and (max-width: (map-get($breakpoints, xs) - 1px)) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          .title:hover {
            color: $accent_blue;
          }

          .title.right-rail {
            min-height: 0;
          }
          .title.noImage {
            max-width: inherit;
          }
        }
      }
    }
    &_content.noImage {
      max-width: inherit;
    }
    &-image{

      @media screen and (min-width: map-get($breakpoints, s)) and (max-width: (map-get($breakpoints, xxl) - 1px))  {
        padding-top: 12px;
      }
      align-self: center;
      .article-img{
        width: 100%;
        height: auto;
        border-radius: 16px;
        margin: 0;
        @media screen and (min-width: map-get($breakpoints, xs))  {
          border-radius: 8px;
        }
        @media screen and (min-width: map-get($breakpoints, m))  {
          width: 68px;
          height: 68px;
        }
        @media screen and (min-width: map-get($breakpoints, xl))  {
          width: 80px;
          height: 80px;
        }
        &--link{
          display: block;
        }
      }
    }
  }

  .no-data-layout{
    position: relative;
    min-height: 150px;
    gap: 20px 0;

    @media screen and (max-width: map-get($breakpoints, xs) - 1px) {
      padding-bottom: 50px;
    }

    //This is to hide 4th dummy article m-web
    .no-show{
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    .dummy-article{
      .content div,
      .img{
        background-color: $grey_hair;
        @media screen  and (min-width: map-get($breakpoints, s)){
          background-color: $marshmallow;
        }
      }

      .content{

        .lin-1{
          max-width: 90%;
          width: 225px;
          height: 15px;
          margin-bottom: 15px;
        }
        .lin-2{
          max-width: 80%;
          width: 201px;
          height: 15px;
          margin-bottom: 15px;
        }
        .lin-3{
          max-width: 70%;
          width: 143px;
          height: 15px;
          margin-bottom: 15px;
        }
        @media screen and (min-width: 1281px) and (max-width: 1679px) {
          .lin-1{
            width: 250px;
          }
          .lin-2{
            width: 224px;
          }
          .lin-3{
            width: 158px;
          }
        }
        @media screen and (min-width: 601px) and (max-width: 1280px) {
          .lin-1{
            width: 190px;
          }
          .lin-2{
            width: 170px;
          }
          .lin-3{
            width: 121px;
          }
        }
        @media screen and (max-width: 600px) {
          .lin-1{
            width: 225px;
          }
          .lin-2{
            width: 201px;
          }
          .lin-3{
            width: 143px;
          }
        }
      }
      .img{
        width: 88px;
        height: 88px;
        border-radius: 12px;
        @media screen and (min-width: 901px) and (max-width: 1679px) {
          display: none;
        }
        @media screen and (max-width: 600px) {
          width: 111px;
          height: 111px;
        }
      }
    }

    .error-message{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @media screen and (min-width: 1281px) {
        justify-content: space-evenly;
      }
      @media screen and (min-width: 901px) and (max-width: 1280px) {
        justify-content: space-evenly;
        column-gap: 28px;
      }
      &__text{
        align-self: center;
        @media screen and (min-width: 901px) and (max-width: 1280px) {
          display: flex;
          column-gap: 50px;
          align-items: center;
        }
        @media screen and (max-width: 900px) {
          text-align: left;
        }
        .message{

          h1 {
            font-family: var(--font-family-heading);
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
          }
          p {
            font-family: var(--font-family-heading);
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            max-width: 340px;
            height: 81px;
          }
        }
        .news-btn{
          border: 1px solid $picasso;
          padding: 12px 20px;
          border-radius: 48px;
          width: 180px;
          height: fit-content;

          a {
            text-decoration: none;
          }
          &__text{
            color: $picasso;
            font-family: var(--font-family-heading);
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      &__image{
        margin-right: 82px;
        align-self: center;
        @media screen and (min-width: 1028px) and (max-width: 1123px) {
          margin: 0;
          align-self: baseline;
        }
        @media screen and (min-width: 901px) and (max-width: 1027px) {
          margin: auto;
        }
        @media screen and (max-width: 480px) {
          margin: 0;
        }
        img{
          width: 165px;
          margin: 0px;
          @media screen and (min-width: 901px) and (max-width: 1280px) {
            width: 133px;
          }
          @media screen and (max-width: 900px) {
            width: 166px;
          }
        }
      }
    }
    @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, s) - 1px) {
      min-height: 75px;
      .error-message{
        top: -25px;
        &__text{
          .message{
            p {
              height: auto;
            }
          }
        }
      }
    }
  }

  .right-rail{
    .no-data-layout{
      .dummy-article{
        margin-bottom: 22px;
        .content{
          .lin-1{
            width: 151px;
            height: 16px;
          }
          .lin-2{
            width: 135px;
            height: 16px
          }
          .lin-3{
            width: 95px;
            height: 16px
          }
        }
        .img{
          width: 80px;
          height: 80px;
          display: block;
        }
      }
      .error-message{
        flex-direction: row;
        &__image{
          margin: 0px;
          .m-image{
            width: 135px;
            height: 135px;
          }
        }
        &__text{
          max-width: 240px;
          text-align: center;
          flex-direction: column  ;
          .news-btn{
            margin: auto;
          }
        }
      }
      .display-none{
        display: none;
      }
    }
  }

  .news-list-width{
    @media screen and (min-width: 901px) and (max-width: 1280px) {
      width: 94%;
    }
  }

  &__lists{

    margin-bottom: 0px;
    row-gap: 56px;
  }
  &__list--new-readmore-rr {
    a {
      text-decoration: none;
      color: $picasso;
      text-align: right;
      font-family: var(--font-family-heading);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 145.455% */
      padding-left: 0;
    }
  }

  &__list {
    margin: 0 0 15px;
    padding-left: 15px;
    column-gap: 40px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1027px) {
      flex-direction: column;
    }

    &_items_mobile_banner{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 100%);
      padding: 0px 20px 20px;
      margin: 0px -10px;
    }

    &_mobile_banner{
      padding-left: 0px;
      margin: 0px;
    }

    &--item {
      color: $batcave;
      line-height: 1.5;
      margin-bottom: 10px;
      padding-left: 10px;

      &--link {
        text-decoration: none;

        &:hover {
          color: $dory;
        }

        &_mobile-banner {
          font-weight: 500;
          font-family: var(--font-family-base);
          font-size: 12px;
          color: #000;
          text-decoration: none;

          &:hover {
            color: #000;
          }
        }
      }

      .m-news__list--link {
        text-decoration: none;

        &:hover {
          color: $dory;
        }
      }

      &_mobile-banner {
        list-style-type: none;
        margin-bottom: 28px;

        .vertical-stream-item {
          position: relative;

          @media screen and (max-width: 750px) {
            padding-top: 0;
            max-height: 135px;
          }

          .image-container {
            float: right;
            // height: 130px;
            height: auto ;
            overflow: hidden;
            position: relative;
            @media screen and (min-width: 709px) and (max-width: 749px) {
              width: 40% ;
            }
            @media screen and (min-width: 900px) and (max-width: 1104px) {
              min-width: 264px ;
              width: 264px ;
            }

            a {
              display: block;
              height: 100%;
              border-radius: 12px;
            }

            .vertical-stream-img {
              margin-left: 20px;
              border-radius: 12px ;
              // height: 100%;
              left: 0;
              // width: auto;
              height: 149px ;
              width: 264px ;
              @media screen and (min-width: $screen-lg) {
                height: 188px ;
                width: 333px ;
              }
              @media screen and (max-width: 899px) {
                width: 100% ;
                min-height: 112px;
                max-height: 112px;
                min-width: 112px;
                max-width: 112px;
              }
            }

            @media screen and (min-width: 900px) and (max-width: 1100px) {
              display: table-cell;
              float: right;
              height: auto;
              vertical-align: middle;
            }

            @media screen and (max-width: 899px) {
              display: table-cell;
              float: right;
              height: auto;
              vertical-align: middle;

              .vertical-stream-img {
                height: auto;
                width: 100%;
              }
            }

            @media screen and (min-width: 709px) and (max-width: 749px) {
              width: 30%;
            }
          }

          .article-info {
            overflow: hidden;
            text-align: left;
            width: auto;

            a {
              color: #232a31;
              text-decoration: none;
              display: block;

              span {
                font-size: 16px;
                font-weight: 700;
                font-family: var(--font-family-heading);
                color: #232A31;
                line-height: 20px;
              }
            }

            @media screen and (max-width: 1100px) {
              display: table-cell;
              float: none;
              text-align: left;
            }

            @media screen and (min-width: 900px) and (max-width: 1100px) {
              width: 50%;
              .excerpt {
                max-height: 80px;
              }
            }

            @media screen and (max-width: 899px) {
              width: 60%;
            }

            @media screen and (min-width: 709px) and (max-width: 749px) {
              width: 70%;
            }

            @media screen and (max-width: 600px) {
              // This is a hack to keep the article info at 50% of the screen width on small screens
              // It is needed due to how the stream rows were set up
              // Converting the stream rows to flex will remove the need for this
              &::after {
                content: '';
                display: block;
                width: 50vw;
              }
            }
          }

          &::after {
            clear: both;
            content: '';
            display: block;
          }
        }
      }
    }

    &--link {
      color: inherit;
    }

    &--readmore {
      color: $dory;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: $cobalt;
      }
    }

    &--readmore_mobile-banner {
      text-align: center;
      padding: 12px 0px 20px;

      &-button {
        text-decoration: none;
        padding: 0 24px;
        display: inline-block;
        color: $accent_blue;
        line-height: 44px;
        font-size: 14px;
        font-family: var(--font-family-heading);
        background-color: #fff;
        border-radius: 48px;
        border: 1px solid $accent_blue;
        font-weight: 600;
        text-align: center;

        &:hover {
          background-color: $accent_blue;
          color: $white;
        }
      }
    }

    &--new-readmore {
      display: flex;
      justify-content: flex-end;
      .readmore{
        padding: 12px 20px;
        text-decoration: none;
        color: #0047ff;
        font-size: 12px;
        font-family: var(--font-family-heading);
        border-radius: 48px;
        border: 1px solid #0047ff;
        font-weight: 600;
        background-color: #FEFFFE;
      }
      @media screen and (min-width: 601px) and (max-width: 900px) {
        justify-content: start;
      }
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }
    &--nonews {
      font-size: 16px;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  &__heading-mobile_banner{
    border-left: 4px solid #ffcb00;
    padding: 2px 0 0 8px;
    font-size: 24px;
    font-family: var(--font-family-heading);
    font-weight: 800;
    letter-spacing: -1px;
    margin: 0px 10px 28px;
  }

  &__inner-mobile_banner{
    padding: 40px 0px 0px;
  }

  &.weather_v2{
    margin-bottom: 0 !important;

    .provider-logo {
      width: 16px;
      height: 16px;
    }

    .provider-name {
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
    @media screen and (max-width: 600px) {
      .provider-logo {
        width: 12px;
        height: 12px;
      }
    }
    @media screen and (max-width: (map-get($breakpoints, s) - 1px)){
      margin-top: 36px;
    }
  }

  .m-news__carousel{
    &__btn{
      position: absolute;
      z-index: 10;
      height: 76px;
      width: 36px;
      border: 0;
      box-shadow: $fluffy-pancake;
      padding: 0;
      display: none;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10));
      background-color: rgba(255, 255, 255, 0.9);

      &[disabled]{
        display: none;
      }
      &:hover{
        background-color: $picasso;
        svg{
          fill: $white;
        }
      }
      &--inner{
        height: 20px;
        width: 20px;

        svg{
          fill: $picasso;
        }
      }
      &--prev{
        left: 0;
        border-top-right-radius: 36px;
        border-bottom-right-radius: 36px;
        padding-right: 10px;
      }
      &--next{
        padding-left: 10px;
        right: 0;
        border-top-left-radius: 36px;
        border-bottom-left-radius: 36px;
      }
    }
    @media screen and (min-width: map-get($breakpoints, xs)) and (max-width: map-get($breakpoints, s) - 1px) {
      scrollbar-width: none; // Firefox Hide scrollbar
      -ms-overflow-style: none; // IE Hide scrollbar
      &::-webkit-scrollbar { // Webkit Hide scrollbar
        display: none;
      }

      overflow-y: scroll;

      .grid-x {
        flex-wrap: nowrap;
      }
      .m-news__carousel__btn{
        display: flex;
        &[disabled]{
          display: none;
        }
      }
    }
  }
}

.m-news.right-rail {
  margin-bottom: 0 !important;
}
